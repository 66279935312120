<!-- https://progi.pro/dannie-i-metodi-komponentov-vue-ischezayut-na-odnom-elemente-pri-vizualizacii-s-pomoshyu-v-for-v-kachestve-kart-vuetify-6429981-->
<template>
  <win-block>
    <template #win-title-left>Квартиры</template>
    <v-progress-linear
        :active="loading"
        color="primary"
        indeterminate
        absolute
        top
    ></v-progress-linear>
    <!--   <v-card flat class="pa-4">
         <v-toolbar flat>
           &lt;!&ndash;        <v-toolbar-title>My Document</v-toolbar-title>&ndash;&gt;
           &lt;!&ndash;        <v-spacer></v-spacer>&ndash;&gt;
           <div>
             <v-text-field
                 label="Имя игрока"
                 outlined
                 dense
                 filled
             ></v-text-field>
           </div>
         </v-toolbar>
       </v-card>
   -->

    <v-row style="opacity: 0.5">
      <v-col cols="6">
        <v-checkbox
            v-model="search.alarm"
            label="Есть уведомления"
            color="primary"
            hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
            v-model="search.owner_is_null"
            label="Владелец не установлен"
            color="primary"
            hide-details
        ></v-checkbox>

      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            v-model="search.text"
            label="Поиск"
            placeholder="кадастр, фамилия или номер квартиры"
            outlined
            dense
            filled
            clearable
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-select
            v-model="flattype_select"
            :items="params.flattype_list"
            item-text="[1]"
            item-value="[0]"
            label="Тип"
            chips
            multiple
            filled
            outlined
            dense
            clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
            v-model="entrances_select"
            :items="params.entrances_list"
            item-text="[1]"
            item-value="[0]"
            label="Подъезд"
            chips
            multiple
            filled
            outlined
            dense
            clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
            v-model="floors_select"
            :items="params.floors_list"
            item-text="[1]"
            item-value="[0]"
            label="Этаж"
            chips
            multiple
            filled
            outlined
            dense
            clearable
        >
        </v-select>
      </v-col>
      <!--
            <v-col cols="12" sm="6">
              <v-select
                  v-model="rank_select"
                  :items="params.rank_list"
                  item-text="[1]"
                  item-value="[0]"
                  label="Звание"
                  chips
                  multiple
                  filled
                  outlined
                  dense
                  clearable
                  menu-props="auto"
              >
              </v-select>
            </v-col>


            <v-col cols="12" sm="6">
              <v-select
                  disabled
                  :items="chips"
                  label="Ярлыки"
                  filled
                  chips
                  outlined
                  dense
                  multiple
                  clearable
              >

                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      x-small
                      @click="()=>{}"
                      @click:close="()=>{}"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>


              </v-select>
            </v-col>-->

    </v-row>

    <v-card flat min-height="300px">


      <v-expansion-panels class="mb-6" focusable>
        <flat v-for="item in items" :key="item['id']"
              :flat="item" :params="params"
              :class="[item.deleted ? 'delete' : '' ]"
        />
      </v-expansion-panels>


      <v-overlay
          absolute
          :opacity=0.8
          color="white"
          :value="loading"
      >
        <v-progress-circular
            indeterminate
            size="30"
            color="teal"
        ></v-progress-circular>
      </v-overlay>

    </v-card>


  </win-block>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "flat": () => import('./flat-panel.vue'),
  },
  data: () => ({
    search: {text: '', alarm: false, flattype: '', entrances: '', floors: '', owner_is_null: false},
    form_karma: false,
    loading: true,
    entrances_select: [],
    flattype_select: [],
    floors_select: [],


    items: [],
    params: {
      entrances_list: [[0, 'нет подъезда']],
      flattype_list: [[0, 'квартира'], [1, 'помещение']],
      right_types: [["OWN", "Собственность"], ["COWN", "Cовместная"], ["SOWN", "Долевая"]],
      tags: [[1, 'реестр обновлен'], [2, 'жалоба'], [3, 'предложение'], [4, 'авария'], [5, 'задолженность']],
      floors_list: [],
      actions: [],
    }
  }),
  created() {
    this.initialize()
    let millisec = 2000
    if (!this.$store.state.develop) millisec = 100
    this.debounce = debounce(this.load_data, millisec)
  },
  watch: {
    entrances_select(val) {
      this.search.entrances = val.join()
    },
    floors_select(val) {
      this.search.floors = val.join()
    },
    flattype_select(val) {
      this.search.flattype = val.join()
    },
    search: {
      deep: true,
      handler() {
        this.debounce()
      }
    }
  },
  methods: {
    initialize() {
      //
      // Загрузка справочников
      //
      this.$axios.get(this.$url.server_project_catalog)
          .then(response => {
            // получаем список [key, value]
            const data = Object.entries(response.data['entrances']);
            data.forEach(([key, value]) => {
              this.params.entrances_list.push([value['number'], key])
            })
            for (let i = 1; i < response.data['floors'] + 1; i++) {
              this.params.floors_list.push([i, 'Этаж ' + i])
            }
            // this.params.categories_log = response.data['categories_log']
            this.params.actions = response.data['actions']
            this.load_data()
          })
    },
    load_data() {
      // window.console.log('this.search ', this.search)
      this.$axios.get(this.$url.server_project_flats, {params: this.search, zLoader: [this, 'loading']})
          .then(response => {
            this.items = response.data
          })
    },
  }

}


// let itm = [
//   {
//     chips:[['пример3',3]],
//     "id": 1640,
//     "number": 921,
//     "floor": 3,
//     "area": "67.70",
//     "apartment": true,
//     "cadNumber": "50:20:0020410:2722"
//   },
//   {
//     chips:[['пример1',1], ['пример2',2]],
//     "id": 1639,
//     "number": 910,
//     "floor": 1,
//     "area": "58.10",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2764"
//   },
//   {
//     chips:[['пример1',1], ['пример2',2], ['пример3',3]],
//     "id": 1638,
//     "number": 906,
//     "floor": 20,
//     "area": "67.50",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2744"
//   },
//   {
//     chips:[],
//     "id": 1637,
//     "number": 903,
//     "floor": 20,
//     "area": "83.90",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2721"
//   },
//   {
//     "id": 1636,
//     "number": 902,
//     "floor": 19,
//     "area": "85.50",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2724"
//   },
//   {
//     "id": 1635,
//     "number": 901,
//     "floor": 19,
//     "area": "67.40",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2763"
//   },
//   {
//     "id": 1634,
//     "number": 898,
//     "floor": 19,
//     "area": "84.00",
//     "apartment": false,
//     "cadNumber": "50:20:0020410:2729"
//   }
// ]
</script>


<style scoped>

.delete {
  opacity: 0.3 !important;
  transition: all;
  transition-duration: 0.7s;
  transition-delay: 0.3s;
}
</style>